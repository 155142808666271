import { createRoutesFromDefinitions, isRouteDef, route, routeP } from './definitions';

import type { Routes, RoutesDef, RoutesDefNode } from './definitions';
import type { BaseUrlType, MyAccountViewType, ResolveUrlOptions } from './types';

/*
 * NOTE: routes must not start with /static or /assets, these are exclusively used for immutable static files
 *       and routes deployed under these paths will not work correctly on AWS.
 */

const routeDefinitions = {
  accounts: {
    index: route('/accounts'),
    create: route('/accounts/create'),
    edit: routeP<{ account_id: string }>('/accounts/:account_id'),
  },
  admin: {
    auditLog: route<{ actor_context?: string, target_context?: string | string[] }>('/admin-audit-log'),
    candidateMatchingDebugger: route('/candidate-matching-debugger'),
    candidateIndexStats: route('/candidate-matching-debugger/index-stats'),
    availableCandidates: route('/admin-available-candidates'),
    emailInbox: route('/admin-email-inbox'),
    emailInboxWithRequestId: routeP<{ mail_tag_request_id: string }>('/admin-email-inbox/request/:mail_tag_request_id'),
    requestCandidateAvailabilities: route('/admin-request-candidate-availabilities'),
    requestCandidateAvailabilitiesWithRequestId: routeP<{request_id?: string }>('/admin-request-candidate-availabilities/request/:request_id'),

    requests: route('/admin-requests'),
    salesforceImport: route('/admin-salesforce-import'),
    dmsImport: route('/admin-dms-import'),
    reports: route('/admin-reports'),
  },
  calendar: {
    index: route<{ request_id?: string }>('/requests-calendar'),
  },
  healthcareInstitutions: {
    index: route('/healthcare-institutions'),
    edit: routeP<{ healthcare_institution_id: string }, { view?: string }>('/healthcare-institutions/:healthcare_institution_id'),
    departments: {
      create: routeP<{ healthcare_institution_id: string }>('/healthcare-institutions/:healthcare_institution_id/departments/create'),
      edit: routeP<{ healthcare_institution_id: string, department_id: string }, { department_view?: 'shifts' | 'edit' }>('/healthcare-institutions/:healthcare_institution_id/departments/:department_id'),
    },
    tokens: {
      create: routeP<{ healthcare_institution_id: string }>('/healthcare-institutions/:healthcare_institution_id/tokens/create'),
      show: routeP<{ healthcare_institution_id: string, token_id: string }>('/healthcare-institutions/:healthcare_institution_id/tokens/:token_id'),
    },
  },
  home: route<{home_view?: 'default'}>('/'),
  analytics: {
    index: route('/analytics'),
  },
  messages: {
    index: route('/messages'),
    feed: routeP<{ feed_id: string }>('/messages/:feed_id'),
  },
  myAccount: {
    index: route<{ account_view?: MyAccountViewType }>('/my-account'),
  },
  myBookings: {
    index: route('/my-bookings'),
    details: routeP<{ booking_id: string }>('/my-bookings/:booking_id'),
  },
  myPools: {
    index: route('/my-pools'),
    institution: routeP<{ healthcare_institution_id: string }>('/my-pools/:healthcare_institution_id'),
  },
  permanentRequests: {
    index: route('/permanent-requests'),
  },
  profile: {
    candidate: routeP<{ candidate_id: string }>('/profile/:candidate_id'),
    candidatePdf: routeP<{ candidate_id: string }, { anonymized: string, userId: string, healthcareInstitutionId?: string, recipientId?: string, hideContactInformation?: string, forwardingProfile?: string }>('/profile-pdf/:candidate_id'),
  },
  requests: {
    index: route<{ filter: string }>('/requests'),
    create: route<{ duplicateRequest: string }>('/requests/create'),
    edit: routeP<{ request_id: string }>('/requests/edit/:request_id'),
    extend: routeP<{ request_id: string }>('/requests/extend/:request_id'),
    newEdit: routeP<{ request_id: string }>('/requests/update/:request_id'),
    request: routeP<{ request_id: string }, { swimlane?: string, chatWidgetFeedId?: string, approveCandidateId?: string, candidateId?: string }>('/requests/:request_id'),
    requestCandidateAvailabilities: routeP<{ request_id: string, candidate_id: string }>('/requests/:request_id/availabilities/:candidate_id'),
    opportunity: routeP<{ opportunity_id: string }>('/requests/opportunity/:opportunity_id'),
  },
  salesDashboard: route('/sales-dashboard'),
  search: {
    index: route('/search'),
  },
  userEnteredCandidatePage: route<{ originalUrl: string }>('/entered-candidate-page'),
  watchlist: {
    index: route('/watchlist'),
  },
  link: {
    opportunity: routeP<{ opportunity_id: string }>('/link/opportunity/:opportunity_id'),
  },
  login: route<{ from?: string }>('/login'),

  public: {
    logout: route<{ from?: string }>('/logout'),
    candidate: {
      bookingRequestDecision: routeP<{ request_id: string, version: string | undefined }, { token?: string }>('/booking-request-decision/:request_id/:version?'),
      candidateLogout: route('/logged-out'),
      chat: routeP<{ feed_id: string }, { token: string }>('/chat/:feed_id'),
      sendAvailabilities: routeP<{ request_id: string, version: string | undefined }, { token?: string | undefined }>('/send-availabilities/:request_id/:version?'),
      unavailable: routeP<{ request_id: string, type: string, version: string | undefined }, { token?: string | undefined }>('/unavailable/:request_id/:type/:version?'),
      tokenExpired: route('/token-expired'),
    },
    changeEmail: {
      confirm: routeP<{ token: string }>('/accounts/email-change/confirm/:token'),
    },
    dataProtection: route('/datenschutz'),
    passwordReset: {
      reset: routeP<{ client_id: string, token: string }>('/password-reset/:client_id/:token'),
      request: route('/password-reset-request'),
    },
    registerInvitedUser: route<{ token?: string }>('/register'),
    redirect: routeP<{service: 'dms'; id: string;}>('/redirect/:service/:id'),
  },
  internal: {
    healthCheck: route('/internal/health-check'),
  },
} as const;

export function* iterateRoutesDef(): Generator<RoutesDef> {
  function* flatten(routesDef: RoutesDefNode): Generator<RoutesDef> {
    if (isRouteDef(routesDef)) {
      yield routesDef;
    } else {
      for (const node of Object.values(routesDef)) {
        yield* flatten(node);
      }
    }
  }

  yield* flatten(routeDefinitions);
}

export function createRoutes<B extends BaseUrlType>(resolveOptions: ResolveUrlOptions<B>): Routes<typeof routeDefinitions, B> {
  return createRoutesFromDefinitions(routeDefinitions, resolveOptions);
}
