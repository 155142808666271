import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React, { useCallback, useContext } from 'react';

import { ErrorUtilContext } from '@@frontend/ReactContext';

interface ErrorSnackbarProps {
  isVisible: boolean;
}

const ErrorSnackbar = ({ isVisible }: ErrorSnackbarProps): JSX.Element => {
  const errorUtil = useContext(ErrorUtilContext);

  const handleClose = useCallback(() => {
    errorUtil.removeError();
  }, [errorUtil]);

  return (
    <Snackbar
      anchorOrigin={ { vertical: 'bottom', horizontal: 'center' } }
      autoHideDuration={ 10000 }
      key={ errorUtil.message }
      onClose={ handleClose }
      open={ isVisible }
      className="print:hidden"
    >
      <Alert
        onClose={ handleClose }
        severity="error"
      >
        { errorUtil.message }
      </Alert>
    </Snackbar>
  );
};

export default ErrorSnackbar;
